import React, { Fragment } from "react"
import { Styled, css } from "theme-ui"

const Footer = ({ socialLinks=[] }) => (
  <footer css={css({mt: 4, pt: 3})}>
    © {new Date().getFullYear()}, Powered by
    {` `}
    <Styled.a href="https://www.gatsbyjs.org">Gatsby</Styled.a>
    {
      socialLinks.map((platform, i, arr) => (
        //  platform.url==='https://github.com/gatsbyjs' ? null :
          <Fragment key={platform.url}>
            {` `}&bull;{` `}
            <Styled.a href={platform.url} target="_blank" rel="noopener noreferrer">
              {platform.name}
            </Styled.a>
          </Fragment>
        ))
    }
  </footer>
)
export default Footer
